import React from 'react';
import styled from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';

function NotFound() {
  return (
    <Component>
      <ContentContainer>
        <Inner>
          <TextError>404</TextError>
          <TextPageNotFound>Страница не найдена</TextPageNotFound>
          <Link to="/">
            <ButtonLink>Перейти на сайт</ButtonLink>
          </Link>
        </Inner>
      </ContentContainer>
    </Component>
  );
}

export default NotFound;

const Component = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
const Inner = styled.div`
  text-align: center;
  padding: 20px 0 10px 0;
`;

const TextError = styled.h1`
  font-size: 44px;
  margin: 0;
`;

const TextPageNotFound = styled.h1`
  font-size: 36px;
  margin: 0 0 30px;
`;

const ButtonLink = styled.button`
  border: 1px solid ${colors.primary};
  color: ${colors.primary};
  font-weight: 600;
  border-radius: 5px;
  width: 230px;
  line-height: 38px;

  &:hover {
    background: ${colors.primary};
    color: ${colors.white};
  }
`;
