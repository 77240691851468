import React from 'react';

import { Page } from '@tager/web-components';

import Layout from '@/components/Layout';
import NotFound from '@/modules/NotFound';

function NotFoundPage() {
  return (
    <Page title="Page not found">
      <Layout>
        <NotFound />
      </Layout>
    </Page>
  );
}

export default NotFoundPage;
