import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Icon } from '@/assets/svg/breadcrumb_arrow.svg';
import { useSettingItem } from '@/hooks';
import Link from '@/components/Link';
import { BreadCrumbInt, BreadCrumbType } from '@/typings/common';
import { colors } from '@/constants/theme';

function BreadCrumb({ breadCrumbs }: BreadCrumbInt) {
  const siteName = useSettingItem('WEBSITE_NAME');
  const breadCrumbList: BreadCrumbType[] = [];

  if (siteName) {
    breadCrumbList.push({
      label: siteName,
      url: '/',
    });
  }

  if (breadCrumbs) {
    breadCrumbList.push(...breadCrumbs);
  }

  return (
    <Component>
      {(breadCrumbList ?? []).map((item, index) => (
        <Inner key={index}>
          {breadCrumbList.length === index + 1 ? (
            <Label>{item.label}</Label>
          ) : (
            <Link to={item.url}>
              <Label>{item.label}</Label>
            </Link>
          )}
          {!(breadCrumbList.length === index + 1) && <Icon />}
        </Inner>
      ))}
    </Component>
  );
}

const Component = styled.ul`
  margin-bottom: 3px;
  padding-bottom: 3px;
  white-space: nowrap;
  overflow: auto;
  display: flex;
  align-items: center;
  margin-top: 15px;

  svg {
    margin: 0 9px;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    position: absolute;
    top: 0;

    border-radius: 4px;
    background-color: ${colors.grayLighter};
  }
`;
const Inner = styled.li`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  color: #7e7e7e;
`;

export default BreadCrumb;
