import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { breakpoints, colors } from '@/constants/theme';
import { PostShort } from '@/typings/model';

type Props = {
  post: PostShort;
};

function PlainPostCard({ post }: Props) {
  return (
    <Component>
      <Content>
        <StyledPicture
          {...convertThumbnailToPictureProps(post?.image?.cover)}
        />
        <Description>
          <Title>{post?.name}</Title>
          <SubTitle>{post?.excerpt}</SubTitle>
        </Description>
        <Category>{post?.category?.name}</Category>
      </Content>
    </Component>
  );
}

export default PlainPostCard;

const Component = styled.div`
  position: relative;
`;

const StyledPicture = styled(Picture)`
  width: 100%;
  height: 50%;
  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
    height: 130px;

    @media (min-width: 1024px) {
      object-fit: cover;
    }
  }
`;

const Content = styled.div`
  height: 290px;
  border: 1px solid ${colors.grayProductCard};
`;

const Description = styled.div`
  padding: 10px 16px 40px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  @media (min-width: ${breakpoints.tabletSmall}) {
    font-size: 17px;
  }

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const SubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  margin-top: 15px;
  @media (min-width: ${breakpoints.tabletSmall}) {
    font-size: 13px;
  }

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Category = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  line-height: 30px;
  font-size: 13px;
  padding: 0 20px;
  background: var(--main-color, #860935);
  color: ${colors.white};

  &:hover {
    background: var(--main-color-hover, #6a072a);
  }
`;
