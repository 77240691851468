import { StoreDispatch } from '@/store/store';
import {
  getCatalogMenuThunk,
  getMenuItemListThunk,
} from '@/store/reducers/tager/menus';
import { getSettingItemListThunk } from '@/store/reducers/tager/settings';
import { getProductListByGroupThunk } from '@/store/reducers/pages/products';
import { getBlogPostListThunk } from '@/store/reducers/tager/blog';

export function getSharedThunkList(dispatch: StoreDispatch) {
  return [
    dispatch(getMenuItemListThunk('top')),
    dispatch(getMenuItemListThunk('main')),
    dispatch(getMenuItemListThunk('footer')),
    dispatch(getMenuItemListThunk('catalog')),
    dispatch(getProductListByGroupThunk('TOP')),
    dispatch(getProductListByGroupThunk('NEW')),
    dispatch(getSettingItemListThunk()),
    dispatch(getCatalogMenuThunk()),
    dispatch(getBlogPostListThunk()),
  ] as const;
}
