import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { breakpoints, colors } from '@/constants/theme';
import { ProductPreview } from '@/typings/model';
import { normalizePrice } from '@/utils/common';

function ProductCard({
  name,
  price,
  image,
  category,
  discountPrice,
}: ProductPreview) {
  return (
    <Component>
      <StyledPicture {...convertThumbnailToPictureProps(image)} />
      <Description>
        <Top>
          <Category>{category}</Category>
          <Title>{name}</Title>
        </Top>
        <Bottom>
          {price && discountPrice ? (
            <>
              <Price>{normalizePrice(price)} руб.</Price>
              <Discount>{normalizePrice(discountPrice)} руб.</Discount>
            </>
          ) : price && !discountPrice ? (
            <>
              <Discount>{normalizePrice(price)} руб.</Discount>
            </>
          ) : null}
        </Bottom>
      </Description>
    </Component>
  );
}

export default ProductCard;

const Component = styled.div`
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  height: 288px;
  border: 1px solid ${colors.grayProductCard};
`;

const StyledPicture = styled(Picture)`
  width: 100%;
  height: 100%;

  picture,
  div,
  img {
    width: 100%;
    height: 120px;
  }
  img {
    object-fit: contain;
    object-position: left;
  }
`;

const Description = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Category = styled.div`
  font-size: 12px;
  line-height: 26px;
  color: #5a5a5a;
  font-weight: 500;

  @media (min-width: ${breakpoints.tabletSmall}) {
    font-size: 14px;
  }
`;
const Title = styled.div`
  font-size: 14px;
  line-height: 26px;
  color: #000;
  font-weight: 500;

  @media (min-width: ${breakpoints.tabletSmall}) {
    font-size: 16px;
  }
`;

const Price = styled.div`
  color: rgb(102, 102, 102);
  font-size: 16px;
  font-weight: 500;
  text-decoration: line-through;
  display: block;
  margin-top: 20px;
`;

const Discount = styled.div`
  display: inline-block;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.black};
  font-weight: 500;

  @media (min-width: ${breakpoints.tabletSmall}) {
    font-size: 22px;
  }
`;

const Top = styled.div``;
const Bottom = styled.div``;
