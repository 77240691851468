export function getQuarterToString(n: number, rate: number) {
  return Number(Math.floor(n * (rate / 100)).toFixed());
}

export function getFinalPrice(
  sum: number,
  rate: number,
  initPayment: number,
  period: number
) {
  const rateToUse = rate / 100;
  const priceWithoutInitPayment = sum - Math.floor(initPayment);
  const priceLeftToPayWithRate = Number(
    (rateToUse > 0
      ? priceWithoutInitPayment * rateToUse + priceWithoutInitPayment
      : priceWithoutInitPayment
    ).toFixed(2)
  );
  const underpayment = Number(
    (priceLeftToPayWithRate - priceWithoutInitPayment).toFixed(2)
  );
  const monthlyPayment = Number((priceLeftToPayWithRate / period).toFixed(2));

  if (initPayment > sum) {
    return {
      price: sum,
      underpayment: 0,
      monthlyPayment: 0,
    };
  }

  return {
    price: sum + underpayment,
    underpayment,
    monthlyPayment,
  };
}
