import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { useSettingItem } from '@/hooks';
import A1 from '@/components/A1';
import Mts from '@/components/Mts';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';

function Helper() {
  const helperText = useSettingItem('HELPER_TEXT');
  const helperQuote = useSettingItem('HELPER_QUOTE');
  const helperNote = useSettingItem('HELPER_NOTE');
  const helperName = useSettingItem('HELPER_NAME');
  const helperPosition = useSettingItem('HELPER_POSITION');
  const a1Number = useSettingItem('CONTACTS_PHONE_A1');
  const mtsNumber = useSettingItem('CONTACTS_PHONE_MTS');
  const helperImage = useSettingItem('HELPER_IMAGE');

  return (
    <ContentContainer>
      <HelperSection>
        <HelperText>{helperText}</HelperText>
        <Phones>
          <A1 title={a1Number} />
          <Mts title={mtsNumber} />
        </Phones>
        <Person>
          <HelperQuote>{helperQuote}</HelperQuote>
          {helperImage && (
            <StyledPicture {...convertThumbnailToPictureProps(helperImage)} />
          )}
        </Person>
        <HelperName>{helperName}</HelperName>
        <HelperPosition>{helperPosition}</HelperPosition>
        <HelperNote>{helperNote}</HelperNote>
      </HelperSection>
    </ContentContainer>
  );
}

export default Helper;

const HelperSection = styled.div``;
const Phones = styled.div`
  max-width: 450px;

  margin-top: 13px;
  display: flex;

  div {
    flex: 1 1 auto;
  }

  & > div:last-child {
    justify-content: flex-end;
  }
`;
const HelperText = styled.p`
  margin-top: 20px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;
const HelperQuote = styled.p`
  margin-top: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: ${colors.gray700};
  flex: 1 1 50%;
`;
const HelperName = styled.p`
  margin-top: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;
const HelperPosition = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;
const HelperNote = styled.p`
  margin-top: 8px;

  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${colors.gray700};
`;
const StyledPicture = styled(Picture)`
  justify-content: flex-end;
  flex: 0 1 50%;
  img {
    max-height: 100px;
  }
`;
const Person = styled.div`
  display: flex;
  align-items: flex-end;
  max-width: 450px;
`;
