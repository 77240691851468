export type initStateKeys =
  | 'periodSelected'
  | 'price'
  | 'initPaymentRate'
  | 'rate'
  | 'initialPayment';

export enum ActionType {
  SETPERIOD = 'setPeriod',
  SETPRICE = 'setPrice',
  SETINITPAYMENTRATE = 'setInitPaymentRate',
  SETRATE = 'setRate',
  SETINITIALPAYMENT = 'setInitialPayment',
}

export interface CalcAction {
  type: ActionType;
  payload: number;
}

export type OutputType = {
  price: number;
  underpayment: number;
  monthlyPayment: number;
};
